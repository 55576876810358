import { createSlice } from "@reduxjs/toolkit";

const storageSlice = createSlice({
  name: "storage",
  initialState: {
    token: "",
    expiryTime: "",
    baseUrl: "",
    cdnUrl: "",
  },
  reducers: {
    setStorageToken: (state, action) => {
      state.token = action.payload.token;
      state.expiryTime = action.payload.expiryTime;
      state.baseUrl = action.payload.baseUrl;
      state.cdnUrl = action.payload.cdnUrl;
    },
    setInitialStorageState: (state) => {
      state.token = "";
      state.expiryTime = "";
      state.baseUrl = "";
      state.cdnUrl = "";
    },
  },
});

export default storageSlice.reducer;
export const { setStorageToken, setInitialStorageState } = storageSlice.actions;

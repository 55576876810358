import { combineReducers } from "redux";
import authReducer from "./slices/authSlice";
import emailOtpReducer from "./slices/emailOtpSlice";
import createPostReducer from "./slices/createPostSlice";
import deviceReducer from "./slices/deviceSlice";
import postReducer from "./slices/postSlice";
import userDataReducer from "./slices/userDataSlice";
import storageReducer from "./slices/storageSlice";
import mainReducer from "./slices/mainSlice";
import btnLoaderReducer from "./slices/btnLoaderSlice";
import linkedinConnectReducer from "./slices/linkedinConnectSlice";
import uploadMediaReducer from "./slices/uploadMediaSlice";
import storage from "redux-persist/lib/storage";
import paymentReducer from "./slices/paymentSlice";
import trendingNewsReducer from "./slices/trendingNewsSlice";
import customizeContentReducer from "./slices/customizeContentSlice";

const appReducer = combineReducers({
  auth: authReducer,
  emailOtp: emailOtpReducer,
  createPost: createPostReducer,
  device: deviceReducer,
  post: postReducer,
  userData: userDataReducer,
  storage: storageReducer,
  btnLoader: btnLoaderReducer,
  linkedinConnect: linkedinConnectReducer,
  main: mainReducer,
  uploadMedia: uploadMediaReducer,
  payment: paymentReducer,
  trendingNews: trendingNewsReducer,
  customizeContent: customizeContentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {
    const {
      auth,
      userData,
      emailOtp,
      createPost,
      post,
      storage,
      btnLoader,
      uploadMedia,
      main,
      linkedinConnect,
      payment,
      trendingNews,
      customizeContent,
      ...rest
    } = state;
    return {
      auth: authReducer(undefined, action),
      userData: userDataReducer(undefined, action),
      emailOtp: emailOtpReducer(undefined, action),
      createPost: createPostReducer(undefined, action),
      post: postReducer(undefined, action),
      storage: storageReducer(undefined, action),
      btnLoader: btnLoaderReducer(undefined, action),
      main: mainReducer(undefined, action),
      uploadMedia: uploadMediaReducer(undefined, action),
      linkedinConnect: linkedinConnectReducer(undefined, action),
      payment: paymentReducer(undefined, action),
      trendingNews: trendingNewsReducer(undefined, action),
      customizeContent: customizeContentReducer(undefined, action),
      ...rest,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;

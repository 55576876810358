// TODO: Change this url to get from environment
export const BASE_URL = "https://api.reachifyme.com";
export const API_URL = process.env.API_URL
  ? process.env.API_URL
  : "https://api.reachifyme.com/monolith";
export const SearchPlaceHolderArray = [
  "Create a LinkedIn Post",
  "Paste YouTube video link",
  "Data Driven Blog Post Ideas",
  "A list of “musts” for a career in your field",
  "Bust common myths about your field",
];
export const RE_CAPTCHA_CLIENT_KEY = "6LcTgEEpAAAAAIZ652nccO7mp-NHVQzaBqqx9umk";

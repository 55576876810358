import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    activeSubscription: null,
    currentPaymentSubscription: null,
    lastUserSubscription: null,
    upcomingSubscription: null,
    currentTime: null,
    isActive: false,
    apiCallingFlag: false,
    suggestedPlans: null,
    isPaymentCardOpenAfterPost: false,
  },
  reducers: {
    SetSubscriptions: (state, action) => {
      state.activeSubscription = action.payload.activeSubscription;
      state.currentPaymentSubscription =
        action.payload.currentPaymentSubscription;
      state.isActive = action.payload.isActive;
      state.currentTime = action.payload.currentTime;
      state.lastUserSubscription = action.payload.lastUserSubscription;
      state.upcomingSubscription = action.payload.upcomingSubscription;
    },
    SetApiCallingFlag: (state, action) => {
      state.apiCallingFlag = !state.apiCallingFlag;
    },
    SetSuggestedPlans: (state, action) => {
      state.suggestedPlans = action.payload;
    },
    SetIsPaymentCardOpenAfterPost: (state, action) => {
      state.isPaymentCardOpenAfterPost = action.payload;
    },
  },
});

export default paymentSlice.reducer;
export const {
  SetSubscriptions,
  SetApiCallingFlag,
  SetSuggestedPlans,
  SetIsPaymentCardOpenAfterPost,
} = paymentSlice.actions;

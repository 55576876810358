import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        userId: null,
        email: null,
        isVerified: false,
        hasPassword: false,
        userAttributes: {
            preferredTopics: []
        },
        filterdPreferences:[]
    },
    reducers: {
        setUserData: (state, action) => { 
            const { userId, email, isVerified,userAttribute,hasPassword } = action.payload;
            state.userId = userId;
            state.email = email;
            state.isVerified = isVerified;
            state.hasPassword = hasPassword;
            state.userAttributes = {
                ...userAttribute, 
                preferredTopics: !userAttribute?.preferredTopics ? [] : userAttribute.preferredTopics
            };
        },
        sethasPassword: (state, action) => { 
            const { hasPassword } = action.payload;
            state.hasPassword = hasPassword;
        },
        setInitialUserDataState: (state) => {
            state.userId = null;
            state.email = null;
            state.isVerified = false;
            state.hasPassword = false;
            state.userAttributes = {};
        },
        setFilterdPreferences: (state, action) => {
            state.filterdPreferences = action.payload;
        },
    },
});

export default userDataSlice.reducer;
export const { setUserData, sethasPassword, setInitialUserDataState, setFilterdPreferences } = userDataSlice.actions;
import React from 'react';
import './styledComponents.scss';

const WelcomeMessage = () => {
    return (
        <div style={{
            padding: '20px 20px',
        }} >
            <div className='welcome-img-container'>
                <img src='/image/welcome-img.svg' alt='welcome' style={{ width: '100%', height: '180px' }} />
            </div>
            <div className='font-size'>
                Help us personalize your content creation journey
            </div>
            <div className='info'>
                Before we get started, we would like to gather your preferences and sync your LinkedIn account. This allows us to provide curated posts, recommendations and more
            </div>
            <div className='message-show-box'>
                <div className='message-show'>
                    <div className='number-show'>
                        1
                    </div>

                    <div className='dark-line'>

                    </div>
                    <div className='number-show'>
                        2
                    </div>
                </div>
                <div className='text-message'>
                    <div>
                        PREFERENCES
                    </div>
                    <div>
                        CONNECT LINKEDIN
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeMessage;

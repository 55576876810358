import api from "../axios/api";
import { API_URL } from "../constant/constants";
import { logout } from "../reducers/slices/authSlice";
export const logoutUser = async (dispatch) => {
  try {
    await api.post(`${API_URL}/users/logout`);
  } catch (err) {
  } finally {
    dispatch({ type: "RESET_STATE" });
    dispatch(logout());
  }
};

export const delay = async (time) => {
  return new Promise(res => {
    setTimeout(res, time);
  });
};
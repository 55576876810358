import './leftbanner.scss';
import Hero from './shared/Hero';
import { strings } from '../../../constant/strings';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Leftbanner = ({ text, backToText }) => {
  //custom hooks
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state?.device?.isMobileDevice);

  //image urls
  const BANNER_URL = "/image/login-left-banner.png";
  const BACK_URL = "/image/icons/backArrow.svg"; 

 //page url
  const PAGE_URL = "https://reachifyme.com/";
  
  return (
    <>
    
      <div >
        {/* <Hero />
        <div className='trust-marker'>
          <img src="/image/others/trust-marker-image.svg" alt="trust-marker" />
        </div>
        <div className='login-profile-image'>
          <img src="/image/others/Artwork.png" alt="login-profile" />
        </div>
        <div className='login-left-banner-text'>
          <div>
            {strings.LeftBannerText.GenerateContent}
          </div>
          <div style={{marginTop:'2vh'}}>
            { strings.LeftBannerText.SkyRocket}<br/>
            with <span>{ strings.ReachifyMe}</span>
          </div>
        </div> */}
        <img src = "/image/others/Panel.svg" alt='panel' className='panel' />
      </div>
      
    </>
  )
}

export default Leftbanner;

import { Container, Grid, Box } from "@mui/material"
import Lottie from "lottie-react"
import yellowLoadingDots from "../../lottie/yellow-loading-dots.json"


const FullPageLoader = ({ text }) => {

    return (
        <Box height="100vh" alignItems="center" display="flex" justifyContent="center" flexDirection="column">
            <div className="logo">
                <img src="/image/logo.png" alt="logo" width="30px" />
                <span>ReachifyMe</span>
            </div>
            <Lottie style={{
                height: '30vh',
                width: '30vh'
            }} animationData={yellowLoadingDots} loop={true} />
            <div className='note-text'>
                {text}
            </div>
        </Box>
    )
}

export default FullPageLoader;
import useRestClient from "../../../utils/RestClient";
import { API_URL } from "../../../constant/constants";
import { useDispatch } from "react-redux";
import {
  setUserData,
  sethasPassword,
} from "../../../reducers/slices/userDataSlice";
import { setVerifyEmailOtp } from "../../../reducers/slices/emailOtpSlice";
import useInspirationApi from "../../inspirationSection/api/inspirationApi";
import { SetInspirationalPostsToInitialState } from "../../../reducers/slices/postSlice";
import { emptyUserAttribute } from "../../protectedRoute/api/protectedRouteApi";

const useSettingsPageApi = () => {
  const { post } = useRestClient();
  const dispatch = useDispatch();
  const { getInspirationPosts } = useInspirationApi();

  const resetUserPassword = ({
    currentPassword,
    newPassword,
    setIsOpen,
    setToaster,
    setLoader,
  }) => {
    const data = {
      currentPassword,
      newPassword,
    };
    post(`${API_URL}/user/reset-password`, {
      data,
      beforeFetch: () => {
        setLoader(true);
      },
      onSuccess: (repsonse) => {
        setIsOpen(false);
        setToaster("Password changed successfully");
        setLoader(false);
      },
      onError: (err) => {
        setToaster(err?.response?.data?.error?.errors?.error);
        setIsOpen(false);
      },
      finally: () => {
        setLoader(false);
      },
    });
  };

  const forgetPassword = ({ email, setLoader, setToastText, setIsOtpSent }) => {
    const data = {
      email,
    };
    post(`${API_URL}/users/login/forgot-password`, {
      data,
      beforeFetch: () => {
        setLoader(true);
      },
      onSuccess: (response) => {
        setToastText("OTP sent successfully");
        dispatch(
          setVerifyEmailOtp({
            email: response.data.data.email,
            expiresAt: response.data.data.expiresAt,
            retryAfter: response.data.data.retryAfter,
            retryInterval: response.data.data.retryInterval,
          })
        );
        setIsOtpSent(true);
        setLoader(false);
      },
      onError: (err) => {
        setToastText(
          err?.response?.data?.error?.errors?.error ?? "Something went wrong"
        );
      },
      finally: () => {
        setLoader(false);
      },
    });
  };

  const setNewPassword = ({
    email,
    otp,
    password,
    setLoader,
    setToastText,
    setIsSuccess,
  }) => {
    const data = {
      email,
      otp,
      password,
    };
    post(`${API_URL}/users/login/set-new-password`, {
      data,
      beforeFetch: () => {
        setLoader(true);
      },
      onSuccess: (response) => {
        dispatch(
          sethasPassword({
            hasPassword: true,
          })
        );
        setLoader(false);
        setIsSuccess(true);
      },
      onError: (err) => {
        setToastText(
          err?.response?.data?.error?.errors?.error ?? "Something went wrong"
        );
      },
      finally: () => {
        setLoader(false);
      },
    });
  };

  const prefferedTopics = ({
    topics,
    setLoader,
    handleClose,
    setToastText,
  }) => {
    const data = {
      topics,
    };
    post(`${API_URL}/user/save/preferred-topics`, {
      data,
      beforeFetch: () => {
        SetInspirationalPostsToInitialState([]);
        setLoader(true);
      },
      onSuccess: (response) => {
        dispatch(
          setUserData({
            userId: response?.data?.data?.user?.userId,
            email: response?.data?.data?.user?.email,
            isVerified: response?.data?.data?.user?.isVerified,
            userAttribute:
              response?.data?.data?.userAttribute !== null
                ? response?.data?.data?.userAttribute
                : emptyUserAttribute,
          })
        );
        setToastText("Preferences saved successfully");
        getInspirationPosts({
          page: 0,
          setIsPageLoading: () => {},
          setIsFirstTimeSuccess: () => {},
        });
        setLoader(false);
        handleClose();
      },
      onError: (err) => {
        setToastText(
          err?.response?.data?.error?.errors?.error ?? "Something went wrong"
        );
      },
      finally: () => {
        setLoader(false);
        handleClose();
      },
    });
  };

  const raiseTicket = ({ title, description, setLoader, setSuccess }) => {
    const data = {
      title,
      description,
    };
    post(`${API_URL}/cs/ticket/create`, {
      data,
      beforeFetch: () => {
        setLoader(true);
      },
      onSuccess: (response) => {
        setSuccess(true);
        setLoader(false);
      },
      onError: (err) => {
        setLoader(false);
        setSuccess(false);
      },
      finally: () => {},
    });
  };

  return {
    resetUserPassword,
    prefferedTopics,
    forgetPassword,
    setNewPassword,
    raiseTicket,
  };
};

export default useSettingsPageApi;

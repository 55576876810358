import React from "react";
import Lottie from "lottie-react";
import yellowLoader from "./yellow-loading-dots.json";
const Spinner = () => {
  return (
    <Lottie
      animationData={yellowLoader}
      loop={true}
      style={{ height: "100%", width: "100%" }}
    />
  );
};

export default Spinner;

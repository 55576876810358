import { createSlice } from "@reduxjs/toolkit";

const linkedinConnectSlice = createSlice({
  name: "linkedinConnect",
  initialState: {
    linkedinConnectStatus: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    },
    linkedinConnectData: [],
  },
  reducers: {
    startLoading: (state) => {
      state.linkedinConnectStatus.isLoading = true;
    },
    stopLoading: (state) => {
      state.linkedinConnectStatus.isLoading = false;
    },
    setSuccess: (state) => {
      state.linkedinConnectStatus.isSuccess = true;
    },
    stopSuccess: (state) => { 
      state.linkedinConnectStatus.isSuccess = false;
    },
    setError: (state, action) => {
      state.linkedinConnectStatus.isError = true;
      state.linkedinConnectStatus.errorMessage = action.payload;
    },
    setLinkedinConnectData: (state, action) => {
      state.linkedinConnectData = action.payload;
    },
    setToInitialStateLinkedin: (state) => { 
      state.linkedinConnectStatus = {
        isLoading: false,
        isSuccess: false,
        isError: false,
        errorMessage: "",
      };
      state.linkedinConnectData = [];
    }
  },
});

export default linkedinConnectSlice.reducer;
export const {
  startLoading,
  stopLoading,
  setSuccess,
  setError,
  setLinkedinConnectData,
  stopSuccess,
  setToInitialStateLinkedin
} = linkedinConnectSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

const mainSlice = createSlice({
  name: "main",
  initialState: {
    suggestedIdeas: null,
    trendingNews: null,
    recommendedIdeas: null,
    recommendedPosts: null,
    isAllChangesSavedToDraft: false,
  },
  reducers: {
    SetSuggestedIdeas: (state, action) => {
      state.suggestedIdeas = action.payload;
    },
    SetTrendingNews: (state, action) => {
      state.trendingNews = action.payload;
    },
    SetRecommendedIdeas: (state, action) => {
      state.recommendedIdeas = action.payload;

      const tempRecommendedIdeas = [];
      action.payload.list.forEach((item) => {
        tempRecommendedIdeas.push({ title: item });
      });
      state.recommendedIdeas = {
        list: [...tempRecommendedIdeas],
        pagingInfo: { ...action.payload.pagingInfo },
      };
    },
    SetMoreSuggestedIdeas: (state, action) => {
      const tempRecommendedIdeas = [];
      action.payload.list.forEach((item) => {
        tempRecommendedIdeas.push({ title: item });
      });
      return {
        ...state,

        recommendedIdeas: {
          ...state.recommendedIdeas,
          list: state.recommendedIdeas?.list?.concat(tempRecommendedIdeas),
        },
      };
    },
    SetMoreTrendingNews: (state, action) => {
      return {
        ...state,
        trendingNews: {
          ...state.trendingNews,
          list: state.trendingNews?.list?.concat(action?.payload?.list ?? []),
        },
      };
    },
    SetRecommendedPosts: (state, action) => {
      state.recommendedPosts = action.payload;
    },
    SetAllChangesSavedToDraft: (state, action) => {
      state.isAllChangesSavedToDraft = action.payload;
    },
    SetToInitialStateMain: (state) => {
      state.suggestedIdeas = null;
      state.trendingNews = null;
      state.recommendedIdeas = null;
      state.recommendedPosts = null;
      state.isAllChangesSavedToDraft = false;
    },
  },
});

export default mainSlice.reducer;
export const {
  SetSuggestedIdeas,
  SetTrendingNews,
  SetRecommendedIdeas,
  SetRecommendedPosts,
  SetMoreSuggestedIdeas,
  SetMoreTrendingNews,
  SetAllChangesSavedToDraft,
  SetToInitialStateMain,
} = mainSlice.actions;

import React from 'react'
import '../../pages/login/login.scss';
import { strings } from '../../../../constant/strings';
const Hero = () => {
    const LOGO_URL = '/image/logo.png';
    return (
        <div className='logo-container'>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img src={LOGO_URL} alt='logo' />
            </div>
            <div style={{ fontFamily: 'inter,sans-serif' }}>
                {strings.ReachifyMe}
            </div>
        </div>
    )
}

export default Hero;

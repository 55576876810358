import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Suspense, lazy, useEffect } from "react";
import LoadScreen from "./components/loadScreen";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  setDeviceDesktop,
  setDeviceMobile,
  setCoachMarkNumber,
} from "./reducers/slices/deviceSlice";
import { useDispatch } from "react-redux";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ProtectedRoute from "./components/protectedRoute";
import PublicRoute from "./components/publicRoute";
import ForgetPassword from "./components/loginPage/pages/ForgetPassword";
import LocationChangeProvider from "./sharedcomponents/providers/LocationChangeProvider";
const MobileApp = lazy(() => import("./components/mobileApp"));
const DesktopApp = lazy(() => import("./components/desktopApp"));
const LoginPage = lazy(() => import("./components/loginPage"));

const AppRouter = () => {
  const dispatch = useDispatch();
  const isDesktopDevice = useMediaQuery("(min-width:900px)");
  dispatch(setCoachMarkNumber(localStorage.getItem("coachMarkNumber") || "0"));
  useEffect(() => {
    if (isDesktopDevice) {
      dispatch(setDeviceDesktop());
    } else {
      dispatch(setDeviceMobile());
    }
  }, [isDesktopDevice]);
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route
            index
            path="/*"
            element={
              <Suspense fallback={<LoadScreen />}>
                {!isDesktopDevice ? <MobileApp /> : <DesktopApp />}
              </Suspense>
            }
          />
          <Route
            exact
            path="/linkedin/callback"
            element={<LinkedInCallback />}
          />
        </Route>
        <Route element={<PublicRoute />}>
          <Route
            path="/login"
            element={
              <Suspense fallback={<LoadScreen />}>
                <LoginPage />
              </Suspense>
            }
          />
        </Route>
        <Route element={<PublicRoute />}>
          <Route
            path="/forgetPassword"
            element={
              <Suspense fallback={<LoadScreen />}>
                <ForgetPassword />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;

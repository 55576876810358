import { createSlice } from "@reduxjs/toolkit";

const customizeContentSlice = createSlice({
  name: "customizeContent",
  initialState: {
    ingressSource: "USER_GENERATED",
    context: "",
    narratives: {},
    selectedNews: {
      newsId: "",
      heading: "",
      description: "",
      imageUrl: "",
    },
    selectedNarrative: null,
    opinion: "",
    useNewsOrFact: false,
    useUserInfo: false,
  },
  reducers: {
    SetCustomizeContentValueToInitial: (state, action) => {
      state.contentSource = "generated";
      state.selectedNarrative = "Story";
      state.opinion = "";
      state.useNewsOrFact = false;
      state.useUserInfo = false;
    },
    SetNarratives: (state, action) => {
      const ingressSource = action.payload.ingressSource;
      const narratives = action.payload.narratives;
      state.narratives[ingressSource] = {
        ingressSource: ingressSource,
        narratives: narratives,
      };
    },
    SetSelectedNarrative: (state, action) => {
      state.selectedNarrative = action.payload;
    },
    SetOpinion: (state, action) => {
      state.opinion = action.payload;
    },
    SetUseNewsOrFact: (state, action) => {
      state.useNewsOrFact = action.payload;
    },
    SetUseUserInfo: (state, action) => {
      state.useUserInfo = action.payload;
    },
    SetSelectedNews: (state, action) => {
      state.selectedNews = action.payload;
    },
    SetValuesOnIdeaGeneration: (state, action) => {
      state.context = action.payload.context;
      state.ingressSource = action.payload.ingressSource;
      state.selectedNews = action.payload.selectedNews;
      state.selectedNarrative = action.payload.selectedNarrative;
      state.opinion = action.payload.opinion;
      state.useNewsOrFact = action.payload.useNewsOrFact;
      state.useUserInfo = action.payload.useUserInfo;
    },
  },
});

export default customizeContentSlice.reducer;
export const {
  SetCustomizeContentValueToInitial,
  SetNarratives,
  SetSelectedNarrative,
  SetOpinion,
  SetUseNewsOrFact,
  SetUseUserInfo,
  SetSelectedNews,
  SetValuesOnIdeaGeneration,
} = customizeContentSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
  name: "post",
  initialState: {
    scheduled: [],
    scheduledPageNumber: 0,
    draft: [],
    draftPageNumber: 0,
    published: [],
    publishedPageNumber: 0,
    loader: false,
    linkedinPostStatus: "",
    postCount: [],
    inspirationPageNumber: 0,
    inspirationPosts: [],
    inspirationPageDataBasedOnPreferences: [],
  },
  reducers: {
    SetScheduledPost: (state, action) => {
      state.scheduled = [action.payload];
      state.scheduledPageNumber = state.scheduledPageNumber + 1;
    },
    SetDraftPost: (state, action) => {
      state.draft = [action.payload];
      state.draftPageNumber = state.draftPageNumber + 1;
    },
    SetPublishedPost: (state, action) => {
      state.published = [action.payload];
      state.publishedPageNumber = state.publishedPageNumber + 1;
    },
    ShowLoader: (state) => {
      state.loader = true;
    },
    HideLoader: (state) => {
      state.loader = false;
    },
    SetLinkedinPostStatus: (state, action) => {
      state.linkedinPostStatus = action.payload;
    },
    SetPostCount: (state, action) => { 
      state.postCount = action.payload;
    },
    SetInspirationalPosts: (state, action) => { 
      state.inspirationPosts = [...state.inspirationPosts, ...action.payload];
      state.inspirationPageDataBasedOnPreferences = [...state.inspirationPageDataBasedOnPreferences, ...action.payload];
      state.inspirationPageNumber = state.inspirationPageNumber + 1;
    },
    SetInspirationalPostForPageZero: (state, action) => { 
      state.inspirationPosts = action.payload;
      state.inspirationPageNumber = 1;
    },
    SetInspirationalPostsToInitialState: (state) => { 
      state.inspirationPosts = [];
      state.inspirationPageNumber = 0;
    },
    UnsetPostCount: (state) => {
      state.postCount = [];
    },
    SetInspirationPageDataBasedOnPreferences: (state, action) => { 
      state.inspirationPageDataBasedOnPreferences = action.payload;
    },
    SetInspirationPageDataBasedOnPreferencesAdded: (state, action) => { 
      state.inspirationPageDataBasedOnPreferences = [...action.payload,...state.inspirationPageDataBasedOnPreferences];
    },
    SetToInitialStatePost: (state) => {
      state.scheduled = [];
      state.scheduledPageNumber = 0;
      state.draft = [];
      state.draftPageNumber = 0;
      state.published = [];
      state.publishedPageNumber = 0;
      state.loader = false;
      state.linkedinPostStatus = "";
      state.postCount = [];
      state.inspirationPageNumber = 0;
      state.inspirationPosts = [];
    }
  },
});

export default postSlice.reducer;
export const {
  SetScheduledPost,
  SetInspirationalPosts,
  SetDraftPost,
  SetPublishedPost,
  ShowLoader,
  HideLoader,
  SetLinkedinPostStatus,
  SetPostCount,
  UnsetPostCount,
  SetToInitialStatePost,
  SetInspirationalPostsToInitialState,
  SetInspirationalPostForPageZero,
  SetInspirationPageDataBasedOnPreferences,
  SetInspirationPageDataBasedOnPreferencesAdded,
} = postSlice.actions;

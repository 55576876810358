import { createSlice } from "@reduxjs/toolkit";

const createPostSlice = createSlice({
  name: "createPost",
  initialState: {
    suggestedTexts: [],
    post: [],
    error: null,
    isLoading: false,
    generatedContentCount: 0,
    homePagePrompt: "",
    prompt: "",
    promptType: "default",
    activePostVersions: [],
    activePost: {
      id: 0,
      content: "",
      hashtags: [],
      selectedHashtags: [],
      draftId: "",
      media: {
        name: [],
        type: "",
        uploadId: [],
        mimeType: "",
        extension: "",
        file: [],
        title: "",
      },
    },
  },
  reducers: {
    ShowLoader: (state, action) => {
      state.isLoading = true;
    },
    HideLoader: (state, action) => {
      state.isLoading = false;
    },
    SetHomePagePrompt: (state, action) => {
      state.homePagePrompt = action.payload;
    },
    SetGeneratedContentCount: (state, action) => {
      state.generatedContentCount = action.payload;
    },
    SetPostNull: (state, action) => {
      state.post = [];
    },
    SetPost1: (state, action) => {
      const data = state.post;
      state.post = [];
      state.post[0] = action.payload;
      if (data[1]) {
        state.post[1] = { ...data[1] };
      }
    },
    SetPost2: (state, action) => {
      console.log("ap2", action.payload);
      const data = state.post;
      state.post = [];
      if (data[0]) {
        state.post[0] = { ...data[0] };
      } else {
        state.post[0] = {};
      }
      // state.post = [{ ...state.post[0] }, {}];
      state.post[1] = action.payload;
    },
    SetMorePost: (state, action) => {
      if (state.post.length < 6) {
        state.post = [...state.post, action.payload];
      }
    },
    SetPrompt: (state, action) => {
      state.prompt = action.payload.prompt;
    },
    SetPromptType: (state, action) => {
      state.promptType = action.payload.promptType;
    },
    SetActivePost: (state, action) => {
      const id = action.payload.id;
      console.log("id", id);
      console.log("draft id", state.post[id].draftId);
      state.activePost.id = 0;
      state.activePost.content = state.post[id].content;
      state.activePost.hashtags = [];
      state.activePost.selectedHashtags = [];
      if (
        state.post[id].draftId !== "" &&
        state.post[id].draftId !== null &&
        state.post[id].draftId !== undefined
      ) {
        console.log("draft id exist");
        state.activePost.draftId = state.post[id].draftId;
      } else {
        console.log("draft id not exist");
        state.activePost.draftId = "";
      }
      // state.activePost.draftId = state.post[id].draftId
      //   ? state.post[id].draftId
      //   : "";
      state.activePost.media = { ...state.activePost.media };
      state.activePostVersions = [
        {
          content: state.post[id].content,
          draftId: state.post[id].draftId ? state.post[id].draftId : "",
          versions: [],
        },
      ];

      console.log("acitive post inside ", state.activePost.content);
    },
    SetCustomActivePost: (state, action) => {
      state.activePost.id = 0;
      state.activePost.content = "";
      state.activePost.hashtags = [];
      state.activePost.selectedHashtags = [];
      state.activePost.draftId = "";
      state.activePostVersions = [
        {
          content: "",
          draftId: "",
          versions: [],
        },
      ];
    },
    SetActivePostData: (state, action) => {
      console.log("action h", action.payload);
      const activePost = state.activePost;
      const { content, hashtags, selectedHashtags, draftId, media, prompt } =
        action.payload;
      activePost.id = 0;
      activePost.content = content;
      activePost.hashtags = hashtags;
      activePost.selectedHashtags = selectedHashtags;
      activePost.draftId = draftId;
      activePost.media = { ...media };
      activePost.media.uploadId = [...media.uploadId];
      state.prompt = prompt;
      state.activePostVersions = [
        { content: action.payload.content, versions: [] },
      ];
    },
    SetEmptyActivePost: (state, action) => {
      state.activePost.id = 0;
      state.activePost.content = "";
      state.activePost.hashtags = [];
      state.activePost.selectedHashtags = [];
      state.activePost.draftId = "";
      state.activePost.media = {
        name: "",
        type: "",
        uploadId: [],
        mimeType: "",
        extension: "",
        file: [],
        title: "",
      };
      state.activePostVersions = [{ content: "", versions: [] }];
    },
    SetNewVersion: (state, action) => {
      // state.activePostVersions[state.activePost.id].versions = [
      //   ...state.activePostVersions[state.activePost.id].versions,
      //   action.payload.type,
      // ];
      state.activePostVersions = [
        ...state.activePostVersions,
        {
          content: action.payload.content,
          versions: [],
          draftId: "",
        },
      ];
      state.activePost.id = state.activePostVersions.length - 1;
      state.activePost.content = action.payload.content;
    },
    SetActiveId: (state, action) => {
      state.activePost.id = state.activePostVersions.length;
    },
    SetActivePostVersion: (state, action) => {
      console.log("action", action.payload.id);
      state.activePost.id = action.payload.id;
      state.activePost.content =
        state.activePostVersions[action.payload.id].content;
      state.activePost.draftId =
        state.activePostVersions[action.payload.id].draftId;
    },
    SetPostMedia: (state, action) => {
      if (
        state.activePost.media.type === action.payload.type &&
        action.payload.type === ".jpg,.png,.jpeg,.gif"
      ) {
        state.activePost.media.name = [
          ...state.activePost.media.name,
          ...action.payload.name,
        ];
        // state.activePost.media.uploadId = [...state.activePost.media.uploadId, action.payload.uploadId];
        state.activePost.media.file = [
          ...state.activePost.media.file,
          ...action.payload.file,
        ];
        state.activePost.media.title = action.payload.title;
      } else {
        state.activePost.media.name = [];
        state.activePost.media.file = [];
        // state.activePost.media.type = action.payload.type;
        state.activePost.media.name = action.payload.name;
        state.activePost.media.mimeType = action.payload.fileMimeType;
        state.activePost.media.extension = action.payload.fileExtension;
        state.activePost.media.file = [...action.payload.file];
        state.activePost.media.title = action.payload.title;
      }
    },
    SetSelectedHashtags: (state, action) => {
      if (state.activePost.selectedHashtags.includes(action.payload)) {
        state.activePost.selectedHashtags =
          state.activePost.selectedHashtags.filter(
            (item) => item !== action.payload
          );
      } else {
        state.activePost.selectedHashtags = [
          ...state.activePost.selectedHashtags,
          action.payload,
        ];
      }
    },
    SetDraftId: (state, action) => {
      const { id, draftId } = action.payload;
      state.post = state.post.map((post, index) => {
        if (index === id) {
          return { ...post, draftId };
        }
        return post;
      });
    },
    SetActivePostDraftId: (state, action) => {
      state.activePost.draftId = action.payload;
      state.activePostVersions[state.activePost.id].draftId = action.payload;
    },
    SetMediaUploadId: (state, action) => {
      if (action.payload.empty) {
        state.activePost.media.uploadId = [];
        state.activePost.media.name = [];
      } else if (
        action.payload.type === state.activePost.media.type &&
        action.payload.type === ".jpg,.png,.jpeg,.gif"
      ) {
        state.activePost.media.uploadId = [
          ...state.activePost.media.uploadId,
          action.payload.storageId,
        ];
      } else {
        state.activePost.media.uploadId = [];
        state.activePost.media.type = action.payload.type;
        state.activePost.media.uploadId = [action.payload.storageId];
      }
    },
    SetActivePostContent: (state, action) => {
      console.log("action to");
      state.activePost.content = action.payload.content;
      state.activePost.draftId = "";
      // state.activePostVersions[state.activePost.id].content =
      //   action.payload.content;
    },
    SetActivePostContentEdit: (state, action) => {
      state.activePost.content = action.payload.content;
      if (!state.activePostVersions[0]) {
        state.activePostVersions[0] = {
          content: action.payload.content,
          draftId: "",
          versions: [],
        };
      } else {
        state.activePostVersions[
          state.activePost.id !== "string" ? state.activePost.id : 0
        ].content = action.payload.content;
      }
    },
    SetActivePostHastags: (state, action) => {
      // if (!state.activePost.hashtags[0]) {
      //   state.post[state.activePost.id].hashtags = action.payload;
      //   state.activePost.hashtags = action.payload;
      // }
      state.activePost.hashtags = [...action.payload];
    },
    SetSuggestedTexts: (state, action) => {
      state.suggestedTexts = action.payload;
    },
    SetMediaFromGoogleImages: (state, action) => {
      if (state.activePost.media.type === ".jpg,.png,.jpeg,.gif") {
        state.activePost.media.uploadId = [
          ...state.activePost.media.uploadId,
          action.payload.uploadId,
        ];
        state.activePost.media.name = [
          ...state.activePost.media.name,
          action.payload.name,
        ];
        state.activePost.media.file = [
          ...state.activePost.media.file,
          action.payload.file,
        ];
      } else {
        state.activePost.media.uploadId = [];
        state.activePost.media.name = [];
        state.activePost.media.file = [];
        state.activePost.media.type = ".jpg,.png,.jpeg,.gif";
        state.activePost.media.mimeType = "image/jpeg";
        state.activePost.media.extension = "jpeg";

        state.activePost.media.name = [
          ...state.activePost.media.name,
          action.payload.name,
        ];
        state.activePost.media.uploadId = [
          ...state.activePost.media.uploadId,
          action.payload.uploadId,
        ];
        state.activePost.media.file = [
          ...state.activePost.media.file,
          action.payload.file,
        ];
      }
    },
    DeletePostMediaFromIndex: (state, action) => {
      function deleteElementAtIndex(arr, index) {
        if (index >= 0 && index < arr.length) {
          return arr.filter((_, i) => i !== index);
        } else {
          return arr;
        }
      }
      const updatedId = deleteElementAtIndex(
        state.activePost.media.uploadId,
        action.payload
      );
      state.activePost.media.uploadId = updatedId;

      const updatedName = deleteElementAtIndex(
        state.activePost.media.name,
        action.payload
      );
      state.activePost.media.name = updatedName;

      const updatedFile = deleteElementAtIndex(
        state.activePost.media.file,
        action.payload
      );
      state.activePost.media.file = updatedFile;
    },
    SetToInitialState: (state, action) => {
      state.suggestedTexts = [];
      state.post = [];
      state.error = null;
      state.isLoading = false;
      state.generatedContentCount = 0;
      state.prompt = "";
      state.promptType = "default";
      state.activePostVersions = [];
      state.activePost = {
        id: "string",
        content: "",
        hashtags: [],
        selectedHashtags: [],
        draftId: "",
        media: {
          name: [],
          type: "",
          uploadId: [],
          mimeType: "",
          extension: "",
          file: [],
          title: "",
        },
      };
    },
  },
});

export default createPostSlice.reducer;
export const {
  ShowLoader,
  HideLoader,
  SetHomePagePrompt,
  SetGeneratedContentCount,
  SetPostNull,
  SetPost1,
  SetPost2,
  SetMorePost,
  SetPrompt,
  SetActivePost,
  SetCustomActivePost,
  SetPostMedia,
  SetSelectedHashtags,
  SetDraftId,
  SetActivePostDraftId,
  SetMediaUploadId,
  SetActivePostData,
  SetActivePostContent,
  SetActivePostHastags,
  SetSuggestedTexts,
  SetNewVersion,
  SetActivePostVersion,
  SetPromptType,
  SetActiveId,
  SetActivePostContentEdit,
  SetEmptyActivePost,
  SetMediaFromGoogleImages,
  DeletePostMediaFromIndex,
  SetToInitialState,
} = createPostSlice.actions;

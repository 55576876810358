import useRestClient from "../../../utils/RestClient";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../../../constant/constants";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import {
  setLinkedinConnectData,
  startLoading,
  stopLoading,
  setSuccess,
  setError,
} from "../../../reducers/slices/linkedinConnectSlice";
import {
  connectWithLinkedInFailed,
  connectWithLinkedInSuccess,
} from "../../../utils/events";

const useLinkedInConnectionApi = () => {
  const dispatch = useDispatch();
  const { post, remove, get } = useRestClient();
  const userId = useSelector((state) => state.userData.userId);

  const { linkedInLogin } = useLinkedIn({
    clientId: "78z6vpm0xbxb9d",
    redirectUri: `${window.location.origin}/linkedin/callback`,
    scope:
      "r_basicprofile r_emailaddress w_member_social rw_organization_admin r_organization_social w_organization_social r_ads_reporting rw_ads r_1st_connections_size",

    onSuccess: (code) => {
      //   setConnectLinkedInProgress(false);
      const data = {
        code,
        state: userId,
        redirectUri: `${window.location.origin}/linkedin/callback`,
      };
      connectLinkedIn(data);
      dispatch(startLoading());
    },
    onError: (error) => {
      //   connectWithLinkedInFailed({});
      //   setConnectLinkedInProgress(false);
      console.log(error);
    },
  });


  const disconnectLinkedIn = ({ linkedinId, setToastText, setIsModalOpen }) => {
    if (!linkedinId || linkedinId === "undefined") return;
    post(`${API_URL}/platform/linkedin/disconnect/${linkedinId}`, {
      beforeFetch: () => {
        console.log("before fetch");
        dispatch(startLoading());
      },
      onSuccess: (repsonse) => {
        console.log("success", repsonse);
        dispatch(setLinkedinConnectData([]));
        localStorage.setItem("onboarding-status", 2);
      },
      onError: (err) => {
        console.log("error", err);
        setToastText(err?.response?.data?.error?.errors?.error ?? "Something went wrong");
      },
      finally: () => {
        console.log("finally");
        dispatch(stopLoading());
        dispatch(setSuccess());
        setIsModalOpen(true);
      },
    })
  };

  const getAuthorisationUrl = ({ setToastText }) => {
    get(`${API_URL}/platform/linkedin/authorization-url`, {
      beforeFetch: () => {
        dispatch(startLoading());
      },
      onSuccess: (response) => {
        console.log(response);
        linkedInLogin({ setToastText });
      },
      onError: (err) => { },
      finally: () => { },
    });
  };

  const connectLinkedIn = (data) => {
    post(`${API_URL}/platform/linkedin/connect`, {
      data,
      beforeFetch: () => {
        dispatch(startLoading());
      },
      onSuccess: (response) => {
        console.log(response.data.data);
        const redata = [];
        redata.push(response?.data?.data);
        //const resData = [...response?.data?.data]
        dispatch(setLinkedinConnectData(redata));
        dispatch(stopLoading());
        setSuccess();
        connectWithLinkedInSuccess(redata);
      },
      onError: (err) => {
        console.log(err);
        connectWithLinkedInFailed(err?.response?.data?.error?.errors?.error ?? "Something went wrong");
      },
      finally: () => {
        dispatch(stopLoading());
        dispatch(setSuccess());
      },
    })
  };

  const isFirstTimeUSer = ({
    email,
    setIsNewUser,
    setIsMailValid,
    setContinueLoader,
  }) => {
    post(`${API_URL}/users/login/user-flow`, {
      data: { email },
      beforeFetch: () => {
        //show loader
        setContinueLoader(true);
      },
      onSuccess: (response) => {
        console.log(response?.data?.data?.flow, "response of signin signp");
        setIsNewUser(response?.data?.data?.flow === "SIGNUP");
        setIsMailValid(true);
        setContinueLoader(false);
      },
      onError: (response) => {
        console.log(response);
        setIsMailValid(false);
        setContinueLoader(false);
      },
      finally: () => {
        //hide loader
        setContinueLoader(false);
      },
    });
  };

  return {
    disconnectLinkedIn,
    getAuthorisationUrl,
    connectLinkedIn,
    linkedInLogin,
    isFirstTimeUSer,
  };
};

export default useLinkedInConnectionApi;

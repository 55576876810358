import React from 'react'
import { Alert, AlertTitle, Container, Box, Button } from '@mui/material'

const FullPageError = ({ onReload }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh"
      }}>
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Failed to load - <strong>Please check your connectivity!</strong>
        </Alert>

        <Button variant="outlined" size="medium" sx={{ mt: 4 }} onClick={onReload}>
          Reload
        </Button>
      </Box>
    </Container>
  )
}

export default FullPageError;

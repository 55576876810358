import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Buttons from "../custombuttons/Buttons";
import "./LinkedInConnection.scss";
import useLinkedInConnectionApi from "./linkedInApis/LinkedInApis";
import { useSelector, useDispatch } from "react-redux";
import {
  stopSuccess,
  stopLoading,
} from "../../reducers/slices/linkedinConnectSlice";
import { toast } from "react-toastify";
import { connectWithLinkedInClick } from "../../utils/events";

const LinkedInConnectModal = ({ isOpen, handleClose, type }) => {
  //custom hooks
  const dispatch = useDispatch();

  //states
  const [loader, setLoader] = useState(false);
  const [toastText, setToastText] = useState("");

  //states from the redux selector
  const isMobile = useSelector((state) => state.device?.isMobileDevice);
  const { isLoading, isSuccess } = useSelector(
    (state) => state.linkedinConnect.linkedinConnectStatus
  );
  const linkedinId = useSelector(
    (state) => state.linkedinConnect.linkedinConnectData[0]?.linkedInId
  );

  //apis from the custom hook
  const { linkedInLogin } = useLinkedInConnectionApi();

  //function to handle linkedin connect
  const handleLinkedInConnect = () => {
    linkedInLogin({ setToastText });

    connectWithLinkedInClick();
  };

  //useEffect to open loader when loading
  useEffect(() => {
    if (isLoading) {
      setLoader(true);
      console.log("loader true");
    } else {
      setLoader(false);
    }
  }, [isLoading]);

  //useEffect to close modal when success
  useEffect(() => {
    if (isSuccess) {
      dispatch(stopSuccess());
      dispatch(stopLoading());
      localStorage.setItem("onboarding-status", 3);
    }
  }, [isSuccess]);

  //useEffect to empty toastText when there is change in toastText
  useEffect(() => {
    if (toastText !== "") {
      toast(toastText);
      setToastText("");
    }
  }, [toastText]);

  return (
    <Box >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box sx={{
          backgroundColor: '',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

        }}>
          <img src='/image/others/linkedin-connect-main.webp' alt='head' style={{
            width: '100%',
            display: isMobile ? 'none' : 'block'
          }} />
          <img src='/image/others/mobile-linkedin-connect.webp' alt='head' style={{
            width: '100%',
            display: isMobile ? 'block' : 'none'
          }} />
        </Box>
        <Box sx={{
          padding: isMobile ? '0px 16px' : '0px 24px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          paddingBottom: isMobile ? '4px' : '0px',
        }}>
          <Box sx={{
            fontSize: isMobile ? '24px' : '35px',
            fontWeight: '500',
            textAlign: 'center',
            mt: '15px',
          }}>
            {isMobile ? " Connect your LinkedIn" : "Connect your LinkedIn to proceed"}
          </Box>
          <Box sx={{
            fontSize: isMobile ? '11.2px' : '14px',
            fontWeight: '400',
            textAlign: 'center',
            mt: '10px',
            color: isMobile ? "#000000" : '#3C3C4399',
            width: isMobile ? '100%' : '75%'
          }}>
            Connect your LinkedIn with ReachifyMe for seamless posting, tailored insights, and a personalized approach
          </Box>
          <Box
            sx={{
              width: "100%",
              display: !isMobile ? "flex" : "block",
              flexDirection: "row",
              justifyContent: "center",
              mt: isMobile ? "" : "16px",
            }}
          >
            <Box
              sx={{
                display: isMobile ? "flex" : "grid",
                flexDirection: isMobile ? "column" : "row",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: isMobile ? "12px" : "24px",
                mt: isMobile ? "16px" : "0",
              }}
            >
              <Box>
                <img src="/image/others/Des6.webp" alt="avatars" width="100%" style={{
                  height: '200px',
                  display: isMobile ? 'none' : 'block'
                }} />
                <img src="/image/others/Mob6.webp" alt="avatars" width="100%" style={{
                  display: isMobile ? 'block' : 'none',
                  height: '135px'
                }} />
              </Box>
              <Box>
                <img src="/image/others/Des7.webp" alt="avatars" width="100%" style={{
                  height: '200px',
                  display: isMobile ? 'none' : 'block'
                }} />
                <img src="/image/others/Mob7.webp" alt="avatars" width="100%" style={{
                  display: isMobile ? 'block' : 'none',
                  height: '135px'
                }} />
              </Box>

            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: !isMobile ? 'absolute' : '',
            bottom: '0',
            padding: isMobile ? "" : '0 24px',
            flexDirection: isMobile ? 'column' : 'row',
          }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                m: !isMobile ? "24px 0px" : "12px 0px",
              }}
            >
              <img src="/image/others/trust.svg" alt="trust" width="35px" />
              <Box
                sx={{
                  fontFamily: "Poppins",
                  fontSize: isMobile ? "10px" : '12px',
                  color: isMobile ? "#625C4D" : "#4D4D4D",
                  fontWeight: isMobile ? "500" : "600",
                  width: isMobile ? "100%" : '70%'
                }}
              >
                Our platform prioritizes your account safety. <span style={{
                  fontWeight: isMobile ? 500 : 300,
                  color: isMobile ? "#625C4D" : "#4D4D4D",
                }}> We do not collect cookies or use automation, unlike other LinkedIn tools.</span>
              </Box>
            </Box>
            <Buttons
              variant={loader ? "loader" : "primary"}
              text="Connect with"
              styles={{
                fontWeight: "600",
                marginBottom: "6px",
                fontSize: isMobile ? "11.2px" : "14px",
                width: isMobile ? "100%" : "200px",
                height: isMobile ? "40px" : "50px",
              }}
              endIcon="linkedinBlack"
              clickHandler={handleLinkedInConnect}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkedInConnectModal;

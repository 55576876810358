import React from 'react';
import './LoadScreen.scss';

const LoadScreen = () => {
    console.log('laoder is on here');
    return (
        <div className='main-loader'>
            <div className="custom-loader"></div>
        </div>
    )
};

export default LoadScreen;  
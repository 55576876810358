import { Recommend } from "@mui/icons-material";

export const strings = {
  //common strings
  ReachifyMe: "ReachifyMe",
  ReachifyMeWelcomeText: "Welcome to ReachifyMe",
  Submit: "Submit",
  Date: "Date",
  Time: "Time",
  Cancel: "Cancel",
  PRiVACY_POLICY_URL: "https://reachifyme.com/html/PrivacyPolicy.html",

  //login/signup page strings
  SignUp: "Sign Up",
  SignIn: "Sign In",
  Login: "Login",
  SignUpWithGoogle: "Sign Up with Google",
  SignUpWithEmail: "or Sign Up with email",
  SignInWithEmail: "or Sign In with email",
  SignInWithGoogle: "Sign In with Google",
  ContinueWithMail: "or continue with email",
  GetFreeAccess: "Get 7 days free",
  InputText: [
    {
      id: "Email Id",
      type: "email",
      placeholder: "Enter your Email ID here",
    },
    {
      id: "Password",
      type: "password",
      placeholder: "Enter your password here",
    },
  ],
  PwdError:
    "Password entered is not alphanumeric or does not contain at least 8 characters.",
  mailError: "Please enter a valid Email Id",
  Note: "Note: Your password must be alphanumeric and length must be at least 8 characters.",
  Already: "Already a user?",
  NoAccout: "Don't have an account?",
  LeftBannerText: {
    GenerateContent: "🔮  Generate content in few clicks",
    SkyRocket: "🚀  Sky-rocket your Personal Brand",
    ReachifyMe: "with ReachifyMe",
    Trust: "Trusted by",
    UserCount: "1000+ Users",
  },

  //verify mail page strings
  HeroText: "Woohoo! You’re getting free access to ReachifyMe for 3 Months",
  EnterCode: "Enter Verification Code",
  SentTo: "We’ve sent a code to ",
  ErrorMessge: "Looks like you’ve entered the wrong code or it has expired",
  SendAgain: "Send Code Again",
  ResendIn: "Resend in ",
  success: "Success!",

  //Reschedule page strings
  Reschedule: {
    Reschedule: "Reschedule Post",
    RescheduleText1:
      "Please choose a date and time that is convenient for you.",
    RescheduleText2: "Recommended : Weekdays 9-11 AM, 1-2 PM, 5-7 PM.",
  },

  //settings page strings
  Settings: {
    Settings: "Settings",
    Rights: "Version 1.0200. 2023 ReachifyMe. All Rights Reserved",
    AccountsInfo: {
      AccountsInfo: "Accounts Information",
      EmailId: "E-Mail Id",
      Password: "Password",
      ChangePassword: "Change Password",
      LinkedAccount: "Linked Account",
      Disconnect: "Disconnect Linkedin",
    },
    MyPreferences: {
      MyPreferences: "My Preferences",
      ManagePreferences: "Manage Preferences",
    },
    HelpSupport: {
      HelpSupport: "Help & Support",
      Text: "For any feedback or queries, please mail us at ",
      HelpMail: "support@reachifyme.com",
      ReleaseNotes: "Release Notes",
      RaiseTicket: {
        RaiseTicket: "Raise Ticket",
        Text: "Your query/concern will be addressed by our support team within 24 hours. You will receive a response from support@reachify.com on your registered email address.",
        placeholder: "Please enter your concern here",
      },
    },
  },

  //disconnect linkedin
  Disconnect: {
    Disconnect: "Disconnect Linkedin",
    Text: "Are you sure you would like to disconnect your LinkedIn Account from ReachifyMe? Syncing with LinkedIn allows for seamless posting",
  },

  //Connect LinkedIn
  Connect: {
    Connect: "Connect LinkedIn",
    Connect2: "You’re just a step away!",
    Text: "It seems you haven’t connected your LinkedIn account with ReachifyMe. Sync now to enjoy a seamless posting experience!",
  },

  //Change Password Modal strings
  ChangePassword: [
    {
      Title: "Enter Current Password*",
      Label: "Enter your current password here",
      Type: "text",
      Name: "oldPassword",
    },
    {
      Title: "Enter New Password*",
      Label: "Enter your new password here",
      Type: "text",
      Name: "newPassword",
    },
    {
      Title: "Confirm New Password*",
      Label: "Enter your new password here",
      Type: "text",
      Name: "confirmPassword",
    },
  ],
  ChangePasswordNote:
    "Note: Your password must be alphanumeric. It should contain a combination of alphabets & numbers",

  //Connect linkedin strings
  ConnectLinkedin: {
    ConnectLinkedin: "Connect LinkedIn",
    ConnectLinkedinText:
      "It seems you haven’t connected your LinkedIn account with ReachifyMe. Sync now to enjoy a seamless posting experience!",
  },

  //cancel modal strings
  CancelModal: {
    Cancel: "Cancel Schedule",
    Text: "Would you like to cancel schedule for this post? Your post will now be saved to Drafts",
  },

  //toggleSection strings
  ToggleSection: {
    Scheduled: "Scheduled",
    Drafts: "Drafts",
    Recent: "Recently Posted",
    Recommended: "Recommended",
    Trending: "Trending",
    Curated: "Curated Posts"
  },

  //logout section
  Logout: {
    Logout: "Logout",
    Text: "Are you sure you want to logout?",
  },

  // Home page
  DashboardLoadingText: "Loading your Dashboard...",

  //Delete Draft Modal
  DeleteDraft: {
    DeleteDraft: "Delete Draft",
    Text: "Are you sure you want to delete this draft?",
    Deleted: "Draft Deleted",
    SuccessText: "Draft deleted successfully!",
    ErrorText: "Failed to delete draft!",
  },

  //Forget password page
  ForgetPassword: {
    EnterMail: "Enter Email Id",
    EnterMailText:
      "Enter email id to setup a new password, we will send you OTP",
    PasswordReset: "Password Reset",
    Reset: "Your password has been successfully reset.",
    ClickToLogin: "Click below to login.",
  },

  //Suggested Texts
  SuggestedTexts: {
    SuggestedTopics: "Trending Topics",
    RecentlySearched: "Recent Searches",
  },

  //inspitation page texts
  InspirationPage: {
    PreferenceNotSelectedText:
      "You have not selected any domains as your preference. Click on Add Preference to add your domains of interest.",
    PreferenceNotSelectedTextBtn: "Add Preferences",
    PreferenceSelectedText1:
      "Below Pre-Cooked posts are a mix of your preferences and general topics",
    PreferncesSelectedText2:
      " as your preference. Click on Edit Preferences to manage/add your domains of interest.",
    PreferncesSelectedTextBtn: "Edit Preferences",
  },
};

import { createSlice } from "@reduxjs/toolkit";

const btnLoaderSlice = createSlice({
    name: "btnLoader",
    initialState: {
        isLoading: false,
    },
    reducers: {
        startLoading: (state) => { 
            state.isLoading = true;
        },
        stopLoading: (state) => { 
            state.isLoading = false;
        }
    }
});

export default btnLoaderSlice.reducer;
export const { startLoading, stopLoading } = btnLoaderSlice.actions;
import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import WelcomeMessage from "./Components/WelcomeMessage";
import { useSelector } from "react-redux";
import Buttons from "../custombuttons/Buttons";
import LinkedInConnectModal from "../linkedinConnection/LinkedInConnectModal";
import PreferencesModal from "../../components/settingsPage/components/PreferencesModal";
import ActiveDot from "./Components/ActiveDot";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "white",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  background:
    "linear-gradient(178.6deg, #FDF9D7 -0.01%, rgba(255, 255, 255, 0) 34.24%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  zIndex: 1000,
  width: 800,
  height: 681,
};

const mobStlye = {
  position: "absolute",
  bottom: "0px",
  bgcolor: "background.paper",
  background:
    "linear-gradient(178.6deg, #FDF9D7 -0.01%, rgba(255, 255, 255, 0) 34.24%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
  border: "none",
  outline: "none",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  boxShadow: 24,
  width: "100%",
  zIndex: 1000,
};

const BottomButtonContainer = ({
  btnText,
  onBoardingStatus,
  setOnBoardingStatus,
}) => {
  const isMobile = useSelector((state) => state.device?.isMobileDevice);
  const handleLetsGoClick = () => {
    if (onBoardingStatus === 0) {
      setOnBoardingStatus(1);
      localStorage.setItem("onboarding-status", 1);
    }
  };
  return (
    <div style={{
      padding: "0px 20px",
      paddingBottom: isMobile ? "8px" : "0px",
      position: isMobile?"":"absolute",
      bottom: "12px",
      right: "0px",
    }}>
      {onBoardingStatus === 0 && (
        <div className="btn-container">
          {/* <div>
                        <ActiveDot active={0} />
                    </div> */}

          <div>
            <Buttons
              text={btnText}
              variant={"primary"}
              styles={{ height: isMobile ? "40px" : "50px" }}
              clickHandler={handleLetsGoClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const CombinedModalForOnboarding = ({
  open,
  handleClose,
  onBoardingStatus,
  setOnBoardingStatus,
}) => {
  //states from the redux selector
  const isMobile = useSelector((state) => state.device?.isMobileDevice);

    //useEffect to handle change the onboarding status after 10 seconds if the onBoardingStatus is 0
    useEffect(() => {
        let  timer;
        if (onBoardingStatus === 0) {
             timer = setTimeout(() => {
                setOnBoardingStatus(1);
                localStorage.setItem("onboarding-status", 1);
            }, 8000);
        } else {
            clearTimeout(timer);
        }
        return () => clearTimeout(timer);
    }, [onBoardingStatus]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            //   onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
            BackdropProps={{
                timeout: 500,
                style: {
                    backdropFilter: 'blur(8px)', // Apply backdrop filter for blur effect
                },
            }}
        >
            <Fade in={open}>
                <Box sx={isMobile ? mobStlye : { ...style }}>
                    {
                        onBoardingStatus === 0 ? <WelcomeMessage />
                            : onBoardingStatus === 1 ?
                                <PreferencesModal
                                    open={true}
                                    btnText={"Set up later"}
                                    btnMainTxt={"Submit"}
                                    handleClose={() => {
                                        setOnBoardingStatus(2);
                                        localStorage.setItem("onboarding-status", 2);
                                    }} />
                                :  <LinkedInConnectModal  />
                    }
                    <BottomButtonContainer btnText={"Let's Go"}
                        onBoardingStatus={onBoardingStatus}
                        setOnBoardingStatus={setOnBoardingStatus}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default CombinedModalForOnboarding;

import axios from "axios";
import { API_URL } from "../constant/constants";

const api = axios.create({
  baseURL: `${API_URL}`,
  headers: {
    "Content-Type": "application/json",
    "X-Alternate-Host": process.env.NODE_ENV === "development" ? "localhost" : null,
  },
  withCredentials: true,
});

export default api;

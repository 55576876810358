import React from 'react';
import success from './success.json';
import Lottie from "lottie-react";

const Success = () => {
  return (
          <Lottie
              animationData={success}
              loop={true}
              style={{ height: "100%", width: "100%" }}
          />
  )
}

export default Success;

import { Tag } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const trendingNewsSlice = createSlice({
  name: "trendingNews",
  initialState: {
    defaultCountry: null,
    countryList: null,
    categories: [
      {
        name: "All",
        isSelected: false,
        isLoading: true,
        totalCount: 0,
        currentPage: 0,
      },
    ],
    news: {
      All: [
        //   {
        //   title: "",
        //   description: "",
        //   tag: "",
        //   publishedOn: "",
        //   imageUrl: "",
        // }
      ],
    },
  },
  reducers: {
    SetCountryandCategoryList: (state, action) => {
      state.defaultCountry = action.payload.defaultCountry;
      state.countryList = action.payload.countryList;
      const tempCategories = action.payload.categories.map((category) => {
        return {
          name: category,
          isSelected: false,
          isLoading: true,
          totalCount: 0,
          currentPage: 0,
        };
      });
      console.log("temp categories", tempCategories);
      const allCategory = state.categories.find(c => c.name === "All") ?? {
          name: "All",
          isSelected: true,
          isLoading: true,
          totalCount: 0,
          currentPage: 0,
        };
      state.categories = [
        {
          ...allCategory,
          isSelected: true,
        },
        ...tempCategories,
      ];
      const tempNews = tempCategories.reduce((acc, category) => {
        acc[category.name] = [];
        return acc;
      }, {});
      state.news = {
        ...state.news,
        ...tempNews,
      };
    },
    SetSelectedCategory: (state, action) => {
      state.categories = state.categories.map((category) => {
        if (category.name === action.payload.name) {
          return {
            ...category,
            isSelected: true,
          };
        }
        return {
          ...category,
          isSelected: false,
        };
      });
    },
    SetDefaultCountry: (state, action) => {
      state.defaultCountry = action.payload.country;
    },
    SetTrendingNews: (state, action) => {
      const category = action.payload.category;
      const news = action.payload.news;
      const method = action.payload.method;
      const pagingInfo = action.payload.pagingInfo;
      const tempNews = news.map((newsItem) => {
        return {
          newsId: newsItem.newsId,
          title: newsItem.title,
          description: newsItem.description,
          category: newsItem.category,
          publishedOn: changeDateFormat(newsItem.publishedOn),
          imageUrl: newsItem.sourceImageUrl,
        };
      });
      state.categories = state.categories.map((category) => {
        if (category.name === action.payload.category) {
          return {
            ...category,
            isLoading: false,
            totalCount: pagingInfo.totalCount,
            currentPage: pagingInfo.currentPage,
          };
        }
        return category;
      });
      if (method === "replace") {
        state.news = {
          ...state.news,
          [category]: tempNews,
        };
        return;
      } else if (method === "append") {
        state.news = {
          ...state.news,
          [category]: [...state.news[category], ...tempNews],
        };
        return;
      }
    },
  },
});

export default trendingNewsSlice.reducer;
export const {
  SetCountryandCategoryList,
  SetSelectedCategory,
  SetDefaultCountry,
  SetTrendingNews,
} = trendingNewsSlice.actions;

//utils for this slice
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const changeDateFormat = (currentDate) => {
  const date = new Date(currentDate);

  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${month} ${day}`;
};

import axios from "axios";
import api from "../axios/api";
import { useMemo } from "react";
const noop = () => {};

const getConfig = (options) => {
  const { headers = {}, ...otherOptions } = options;

  return {
    headers: {
      ...headers,
    },
    ...otherOptions,
  };
};

const exchange = (promise, options) => {
  (options?.beforeFetch || noop)();
  promise
    .then(options?.onSuccess || noop)
    .catch(options?.onError || noop)
    .finally(options?.finally || noop);
};

const useRestClient = () => {
  const token = localStorage.getItem("app-token");
  return useMemo(() => {
    // const authHeader = token ? { 'X-Refresh-Token': `${token}` } : {};
    const get = (uri, options) =>
      exchange(api.get(uri, getConfig(options)), options);

    const post = (uri, { data, ...options }) =>
      exchange(api.post(uri, data, getConfig(options)), options);

    const put = (uri, { data, ...options }) =>
      exchange(api.put(uri, data, getConfig(options)), options);

    const patch = (uri, { data, ...options }) =>
      exchange(api.patch(uri, data, getConfig(options)), options);

    const remove = (uri, options) =>
      exchange(api.delete(uri, getConfig(options)), options);

    return { get, post, put, patch, remove };
  }, [token]);
};

export default useRestClient;

import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: localStorage.getItem("X-Refresh-Token") !== null,
    refreshToken: localStorage.getItem("X-Refresh-Token"),
    isOtpNeeded: false
  },
  reducers: {
    login: (state, action) => {
      const { refreshToken } = action.payload;
      state.refreshToken = refreshToken;
      state.isLoggedIn = true;
      localStorage.setItem("X-Refresh-Token", refreshToken);
      if (localStorage.getItem("isOtpNeeded") === null) {
         localStorage.setItem("isOtpNeeded", 1);
      }
    },
    logout: (state) => {
      localStorage.removeItem("X-Refresh-Token");
      sessionStorage.removeItem("isLinkedinModalOpen");
      localStorage.removeItem("recentSearches");
      localStorage.removeItem("onboarding-status");
      state.refreshToken = null;
      state.isLoggedIn = false;
    },
    SetIsOtpNeeded: (state, action) => {
      state.isOtpNeeded = action.payload;
    },
  },
});

export default authSlice.reducer;
export const { login, logout, SetIsOtpNeeded } = authSlice.actions;

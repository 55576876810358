import { createSlice } from "@reduxjs/toolkit";

const emailOtpSlice = createSlice({
  name: "emailOtp",
  initialState: {
    emailId: null,
    verifyEmailOtp: {
        email: null,
        expiresAt: null,
        retryAfter: null,
        retryInterval: 0,
    },
  },
  reducers: {
    setEmailId: (state, action) => {
      const email = action.payload.email;
      state.emailId = email;
    },
    setVerifyEmailOtp: (state, action) => {
      const verifyEmailOtp = action.payload;
      state.verifyEmailOtp = verifyEmailOtp;
    },
    unsetVerifyEmailOtp: (state) => {
      const nullData = {
        email: null,
        expiresAt: null,
        retryAfter: null,
        retryInterval: 0,
      };
      state.verifyEmailOtp = nullData;
    },
  },
});

export default emailOtpSlice.reducer;
export const { setEmailId, setVerifyEmailOtp, unsetVerifyEmailOtp } =
  emailOtpSlice.actions;

import useRestClient from "../../../utils/RestClient";
import { useDispatch } from "react-redux";
import { API_URL } from "../../../constant/constants";
import {
  SetInspirationalPosts,
  SetInspirationalPostForPageZero,
} from "../../../reducers/slices/postSlice";
import { queryParameterBuilder } from "../../../utils/apiHelper";

const useInspirationApi = () => {
  const { get, post } = useRestClient();
  const dispatch = useDispatch();

  const getInspirationPosts = ({
    page,
    setIsPageLoading,
    setIsFirstTimeSuccess,
  }) => {
    get(`${API_URL}/platform/linkedin/curated-posts/?page=${page}`, {
      beforeFetch: () => {
        setIsPageLoading(true);
      },
      onSuccess: (response) => {
        dispatch(
          SetInspirationalPostForPageZero(response?.data?.data?.list ?? [])
        );
        //console.log(response.data.data);
        setIsPageLoading(false);
        setIsFirstTimeSuccess(true);
      },
      onError: (err) => {
        setIsPageLoading(false);
      },
      finally: () => {
        setIsPageLoading(false);
      },
    });
  };

  const getInspirationMorePosts = ({ page, setIsGenerateMoreLoading }) => {
    get(`${API_URL}/platform/linkedin/curated-posts/?page=${page}`, {
      beforeFetch: () => {
        setIsGenerateMoreLoading(true);
      },
      onSuccess: (response) => {
        dispatch(SetInspirationalPosts(response?.data?.data?.list ?? []));
        setIsGenerateMoreLoading(false);
      },
      onError: (err) => {
        setIsGenerateMoreLoading(false);
      },
      finally: () => {
        setIsGenerateMoreLoading(false);
      },
    });
  };

  const getInspirationPostOnFilteredPreferences = ({
    page,
    preferences,
    setIsPageLoading,
  }) => {
    get(
      `${API_URL}/platform/linkedin/curated-posts/?page=${0}${queryParameterBuilder(
        { name: "tags", data: preferences }
      )}`,
      {
        beforeFetch: () => {
          setIsPageLoading(true);
        },
        onSuccess: (response) => {
          dispatch(
            SetInspirationalPostForPageZero(response?.data?.data?.list ?? [])
          );
          setIsPageLoading(false);
        },
        onError: (err) => {
          setIsPageLoading(false);
        },
        finally: () => {
          setIsPageLoading(false);
        },
      }
    );
  };

  const getMoreInspirationPostOnFilteredPreferences = ({
    page,
    preferences,
    setIsGenerateMoreLoading,
  }) => {
    const data = {
      tags: preferences,
    };
    get(
      `${API_URL}/platform/linkedin/curated-posts/?page=${page}${queryParameterBuilder(
        { name: "tags", data: preferences }
      )}`,
      {
        beforeFetch: () => {
          setIsGenerateMoreLoading(true);
        },
        onSuccess: (response) => {
          dispatch(SetInspirationalPosts(response?.data?.data?.list ?? []));
          setIsGenerateMoreLoading(false);
        },
        onError: (err) => {
          setIsGenerateMoreLoading(false);
        },
        finally: () => {
          setIsGenerateMoreLoading(false);
        },
      }
    );
  };

  //{tag : data,  topics : [{isLoading : true , topic : null} ,{isLoading : true , topic : null},{isLoading : true , topic : null}]}
  const getRecommendedIdeas = ({
    pageSize,
    page,
    tags,
    onRecommendedIdeasFetched,
  }) => {
    get(
      `${API_URL}/platform/linkedin/topics/recommended-ideas/grouped?pageSize=${pageSize}&page=${page}${queryParameterBuilder(
        { name: "tags", data: tags }
      )}`,
      {
        beforeFetch: () => {},
        onSuccess: (response) => {
          const data = response.data.data.map((item) => {
            if (item.tag === "default") {
              return { ...item, tag: "Others" };
            }
            return item;
          });
          // Move "Others" to the end
          const others = data.find((item) => item.tag === "Others");
          const filteredData = data.filter(
            (item) =>
              item.tag !== "Others" && (item.pagingInfo?.totalCount ?? 0)
          );
          if (others) {
            filteredData.push(others);
          }
          onRecommendedIdeasFetched(filteredData);
        },
        onError: (err) => {},
        finally: () => {},
      }
    );
  };
  const getCountriesAndCategories = ({ countryCode, responseHandler }) => {
    get(
      `${API_URL}/platform/linkedin/topics/trending-news/countries-and-categories${
        countryCode ? "?countryCode=" + countryCode : ""
      }`,
      {
        beforeFetch: () => {},
        onSuccess: (response) => {
          responseHandler(response.data.data);
        },
        onError: (err) => {
          responseHandler(null);
        },
        finally: () => {},
      }
    );
  };
  const getTrendingNews = ({
    pageSize,
    page,
    countryCode,
    category,
    responseHandler,
  }) => {
    const buildQuery = () => {
      let query = `?pageSize=${pageSize}&page=${page}`;
      if (countryCode) {
        query = `${query}&countryCode=${countryCode}`;
      }
      if (category) {
        query = `${query}&category=${category}`;
      }
      return query;
    };
    get(
      `${API_URL}/platform/linkedin/topics/trending-news/with-details${buildQuery()}`,
      {
        beforeFetch: () => {},
        onSuccess: (response) => {
          responseHandler(response.data.data);
        },
        onError: (err) => {
          responseHandler(null);
        },
        finally: () => {},
      }
    );
  };

  return {
    getInspirationPosts,
    getInspirationMorePosts,
    getInspirationPostOnFilteredPreferences,
    getMoreInspirationPostOnFilteredPreferences,
    getRecommendedIdeas,
    getCountriesAndCategories,
    getTrendingNews,
  };
};

export default useInspirationApi;

import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Buttons from "../../../../sharedcomponents/custombuttons/Buttons";
import useMediaQuery from "@mui/material/useMediaQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  zIndex: 1000,
};

const mobStlye = {
  position: "absolute",
  bottom: "0px",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  boxShadow: 24,
  p: 2,
  pb: 4,
  width: "100%",
  zIndex: 1000,
};

const UnsavedChanges = ({
  open,
  closeModal,
  leaveWithoutSaving,
  saveAndLeave,
}) => {
  const isMobile = !useMediaQuery("(min-width:480px)");

  const handleClose = () => closeModal();

  const handleSaveAndLeave = () => {
    // saveAndLeave();
    console.log("save and leave");
    saveAndLeave();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => closeModal()}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={isMobile ? mobStlye : style}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: isMobile ? "16px" : "20px", fontWeight: "500" }}
          >
            Save as draft before leaving?
          </Typography>
          <Typography
            id="transition-modal-description"
            sx={{
              mt: 1,
              fontSize: isMobile ? "12px" : "14px",
              color: "#4d4d4d",
            }}
          >
            Your page has some unsaved changes. You will not be able to recover
            this content. Would you like to save the current version to drafts?
          </Typography>
          <Typography
            id="transition-modal-description"
            sx={{
              mt: 1,
              fontSize: isMobile ? "12px" : "14px",
              color: "#4d4d4d90",
              fontStyle: "italic",
            }}
          >
            Note: You will need to save other versions separately as drafts
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "left", mt: 3, gap: 2 }}>
            <Buttons
              variant="primary"
              text="Save & leave"
              // width={isMobile ? "46%" : "40%"}
              styles={{ fontSize: "14px" }}
              clickHandler={() => {
                handleSaveAndLeave();
              }}
            />
            <Buttons
              variant="outlined"
              text="Leave without saving"
              styles={{ fontSize: "14px" }}
              // width={isMobile ? "46%" : "40%"}
              clickHandler={leaveWithoutSaving}
            />
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
export default UnsavedChanges;
